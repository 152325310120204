<template>
  <v-container>
    <h1 v-html="$t('segmentation_form_differences_title')"></h1>
    <v-form ref="segmentationFormSearchDiffernces">
      <v-row class="row search spacer-md">
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-dairy ref="search-dairy" @download="download" />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-segmentation-grouping ref="search-segmentation-grouping" @download="download" />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="2">
          <search-segmentation ref="search-segmentation" @download="download" />
        </v-col>
      </v-row>
      <v-btn color="primary" @click="download"><span v-html="$t('segmentation_form_differences_download')" /></v-btn>
      <v-btn color="secondary" @click="reset"><span v-html="$t('segmentation_form_differences_reset')" /></v-btn>
    </v-form>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import { fileCreator, showError } from '@/services/axios'
import _ from 'lodash'
import SearchSegmentationGrouping from '@/components/searchCards/searchSegmentationGrouping.vue'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import SearchSegmentation from '@/components/searchCards/searchSegmentation.vue'
import { useSearchSegmentationStore } from '@/store/SearchSegmentationStore'
import { useSearchSegmentationGroupingStore } from '@/store/SearchSegmentationGroupingStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationForms_reports_differences',
  data() {
    return {}
  },
  computed: {
    term(): any {
      return [...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items)]
    }
  },
  components: {
    SearchSegmentationGrouping,
    SearchDairy,
    SearchSegmentation: SearchSegmentation
  },
  methods: {
    queryParams() {
      return {
        term: Term.stringify(this.term),
        groupBySegmentationType: useSearchSegmentationGroupingStore().groupBySegmentationType,
        groupByFeeding: useSearchSegmentationGroupingStore().groupByFeeding,
        groupByDate: useSearchSegmentationGroupingStore().groupByDate,
        groupByDairy: useSearchSegmentationGroupingStore().groupByDairy,
        segmentationType: useSearchSegmentationStore().segmentationType,
        feeding: useSearchSegmentationStore().feeding,
        validUntilSearchRangeMin: useSearchSegmentationStore().validFrom,
        validUntilSearchRangeMax: useSearchSegmentationStore().validUntil,
        status: useSearchSegmentationStore().status,
        reportingObligation: useSearchSegmentationStore().reportingObligation,
        difference: useSearchSegmentationStore().difference,
        posNr: useSearchSegmentationStore().posNr,
        dairyOutIdent: useSearchSegmentationStore().dairyOutIdent,
        dairyInIdent: useSearchSegmentationStore().dairyInIdent,
        delimitationOnly: useSearchSegmentationStore().delimitationOnly,
        modificationFrom: useSearchSegmentationStore().modificationFrom,
        modificationUntil: useSearchSegmentationStore().modificationUntil,
        correction: useSearchSegmentationStore().correction
      }
    },
    async download() {
      if (!(await this.$refs.segmentationFormSearchDiffernces.validate()).valid) return false
      var el = this.queryParams()
      let url = '/segmentationForms/reports/differences'
      if (el.segmentationType == 'BC') {
        el.segmentationType = ['B', 'C'] + ' '
      }
      try {
        const response = await this.axios.get(apiURL + url, {
          params: el,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'segmentationFormsReportDifferences.xlsx')
      } catch (e) {
        showError(e)
      }
    },
    reset() {
      this.$refs['search-dairy'].reset()
      this.$refs['search-segmentation-grouping'].reset()
      this.$refs['search-segmentation'].reset()
    }
  }
})
</script>
